.mobile-320,
.mobile-400,
.mobile-500,
.mobile-320-400,
.mobile-400-500,
.mobile,
.mobile-table,
.mobile-tabletDevice,
.desktop,
.desktop-table {
  display: none;
}

@media (min-width: 0px) and (max-width: 320px) {
  .mobile-320 {
    display: inline;
  }
}

@media (min-width: 0px) and (max-width: 399px) {
  .mobile-400 {
    display: inline;
  }
}

@media (max-width: 500px) {
  .mobile-500 {
    display: inline;
  }
}

@media (min-width: 321px) and (max-width: 400px) {
  .mobile-320-400 {
    display: inline;
  }
}

@media (min-width: 401px) and (max-width: 500px) {
  .mobile-400-500 {
    display: inline;
  }
}

@media (min-width: 374px) and (max-width: 1000px) {
  .mobile-375-1000 {
    display: inline;
  }
}

@media (max-width: 500px) {
  .mobile {
    display: inline;
  }
  .mobile-table {
    display: table-cell;
  }
}

@media (max-width: 1099px) {
  .mobile-tabletDevice {
    display: inline;
  }
}

@media (min-width: 501px) {
  .desktop {
    display: inline;
  }
  .desktop-table {
    display: table-cell;
  }
}

.transparent {
  background-color: transparent !important;
  box-shadow: none !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
